.Contact {
    overflow: hidden;
    background-image: url("../../../images/Building-in-the-city.svg");
    position: relative;
    padding: 1em;
    margin-bottom: 1em;
}

.Contact .white-bg-image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.959);
}

.Contact .content {
    display: flex;
    flex-direction: row-reverse;
    /* border: 5px solid; */
    align-items: center;
    justify-content: center;
    position: relative;

}

.Contact .text-container {
    padding: 3em;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-radius: 0px 45px 45px 0px;
    direction: rtl;
    max-width: 30%;
}

.Contact .text-container .text {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.Contact .description {
    line-height: 1.5em;
}


.Contact .small-line-deco {
    width: 80px;
    height: 5px;
    background-color: var(--dark-purple);
}

.Contact .text-container .text h2 {
    color: var(--dark-purple);
    font-family: unset;
    font-weight: 900;
    font-size: 2em;
}

.Contact .text-container .text span {
    font-weight: 900;
}

.Contact .contact {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 15px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    padding: 2em;
    justify-content: space-evenly;
    text-align: center;
    gap: 1em;
    direction: rtl;
    height: 600px;
}

.Contact .content form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 1em;
}


.Contact .content form .send-btn {
    background-color: var(--dark-purple);
    color: white;
    font-family: unset;
    font-size: 30px;
    font-weight: 700;
    border: none;
    border-radius: 25px;
    grid-row: 1/5;
    grid-column: 3/4;
}

.Contact .send-btn:hover {
    cursor: pointer;
    background-color: rgb(40, 20, 53) !important;

}

.Contact .message {
    grid-column: 4/2;

}

.Contact input {
    padding-right: 1em;
}

.Contact textarea,
.Contact input {
    padding: 0.5em;
}

.Contact textarea {
    grid-column: -4/-2;
}

.Contact .message input {
    display: flex;
}

.Contact input::placeholder,
.Contact textarea::placeholder {
    color: black;
    font-size: 1em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Contact .contact h2 {
    font-size: 3em;
}

.Contact .contact p {
    font-size: 1.2em;
}

.Contact .social-container {
    font-size: 1.3em;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1em
}


.Contact .social-container>* {
    display: flex;
    gap: 1em;
    align-items: center;

}


/* Responsive */

/* MOBILE */

@media (max-width: 547px) {

    .Contact {
        background-repeat: no-repeat;
    }

    .Contact .content {
        display: flex;
        flex-direction: column;
        /* border: 5px solid; */
        align-items: center;
        justify-content: center;
        gap: 2em;

    }

    .Contact .text-container {
        border-width: 1px;
        border-style: solid;
        border-radius: 45px;
        max-width: 90%;
    }

    .Contact .content form {
        display: flex;
        flex-direction: column;
        display: solid 2px;
    }

    .Contact .contact h2 {
        font-size: 2em;
    }

    .Contact .contact {
        width: 90%;
    }

    .Contact .contact-headline p {
        font-size: 1em;
    }

    .Contact .email p {
        font-size: 80%;
    }

    .Contact .phone p {
        font-size: 80%;

    }

    .Contact .form-status {
        color: yellow;
    }



}

@media (max-width: 768px) {

    .Contact {
        background-repeat: no-repeat;
    }

    .Contact .content {
        display: flex;
        flex-direction: column;
        /* border: 5px solid; */
        align-items: center;
        justify-content: center;
        gap: 2em;

    }

    .Contact .text-container {
        border-width: 1px;
        border-style: solid;
        border-radius: 45px;
        max-width: 90%;
    }


}

@media (min-width: 750px) and (max-width:1300px) {
    .Contact .content {
        display: flex;
        flex-direction: column;
        /* border: 5px solid; */
        align-items: center;
        justify-content: center;
        gap: 2em;

    }

    .Contact .text-container {
        border-width: 1px;
        border-style: solid;
        border-radius: 45px;
        max-width: 90%;
    }
}