.layout {
    /* height: 100%; */
 
}

.layout header {
    z-index: 6;
    position: relative;
}

.layout main {
  flex:1;
  display: flex;
  flex-direction: column;
  gap:2em;
}


