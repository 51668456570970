.Header {
    height: 100%;
}

.Header .upper-line {
    background: var(--light-purple);
    height: 35px;
}

.Header .lower-line {
    background-color: white;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15em;
    padding-right: 15em;
    border-bottom: 0.5px solid rgb(218, 218, 218);
}

.Header .phone-btn {
    background-color: var(--dark-purple);
    border: none;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 0.2em 2em;
    border-radius: 30px;
}

.Header .phone-btn:hover {
    cursor: pointer;
    background-color: rgb(80, 18, 66);
   
}


.Header .phone-icon-container {
    height: 40px;
    width: auto;
}

.Header .phone-icon-container img {
    height: 100%;
    width: 100%;
}

.Header .phone-number {
    color: white;
    font-weight: bold;
    font-family: "Arimo", sans-serif;
    font-size: 1rem;
}

.Header .logo-container {
    background-color: white;
    width: 200px;
    height: auto;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    margin-top: 4em;
    padding: 0.5em;

}

.Header .logo-container img {
    width: 90%;
    height: 90%;
}

@media (max-width: 1100px) {


    .Header .lower-line {
        padding-left: 5.5em;
        padding-right: 5.5em;
    }

    .Header .phone-number {
        display: none;
    }

    .Header .phone-btn {
        padding: 0.2em 0.2em;
    }

    .Header .logo-container {
        width: 132px;

    }




}

@media (max-width: 576px) {


    .Header .lower-line {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }

    .Header .phone-number {
        display: none;
    }

    .Header .phone-btn {
        padding: 0.2em 0.2em;
    }

    .Header .logo-container {
        width: 132px;

    }


}

@media (max-width: 400) {
    
}