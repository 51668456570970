*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

}

:root {
  --light-purple: #818FB4;
  --dark-purple: #425585;

}

body {
  font-family: "Open Sans", sans-serif;
}

body,
#root {
  height: 100vh;
}