.Hero .hero-content {
    display: flex;
    justify-content: center;
    padding-left: 12em;
    padding-right: 12em;
    height: calc(100vh - 140px);
    background-image: url("../../../images/interior-space-office-building-scaled.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 2;
}

.Hero .v-mark {
    height: 15px;
}

.Hero .left-chevron {
    height: 40px;
}

.Hero .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.822);
    z-index: -1;

}

.Hero .hero-image {
    height: calc(100vh - 150px);
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.Hero .hero-image img {
    height: auto;
    width: 110%;
    transform: rotate(358deg);

}

.Hero .hero-copy {
    padding-top: 10em;
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    max-width: 800px;
    margin-bottom: 10em;
}

.Hero .hero-copy .third-sen {
    max-width: 550px;
    font-size: 1.2em;
}

.Hero .ask-for-details-btn {
    border: none;
    background-color: var(--dark-purple);
    color: white;
    font-size: 1.2em;
    padding: 0.3em 1.5em;
    border-radius: 30px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    position: relative;
    z-index: 80;
}

.Hero .ask-for-details-btn:hover {
    background-color: rgb(8, 8, 46);
    cursor: pointer;

}

.Hero .first-sen {
    font-size: 1.8em;
    font-family: unset;
}

.Hero h2 {
    color: var(--dark-purple);
    font-size: 3.5em;
    font-family: unset;
    font-weight: 800;
    line-height: 1em;
}

.Hero .third-sen span {
    font-weight: 800;

}

.Hero .three-points {
    display: flex;
    gap: 1em;
    font-size: 1.5em;
}

.Hero .three-points>* {
    display: flex;
    gap: 0.2em;
    align-items: center;

}

.Hero .hero-lower-wave {
    width: 100%;
    z-index: 10;
    position: relative;
    bottom: 210px;
    pointer-events: none
}

.Hero .rectangle-wave {
    background: var(--dark-purple);
    display: flex;
    padding-left: 12em;
    padding-right: 12em;
    z-index: 2;
    position: relative;
    bottom: 220px;
}



.Hero .services-icons {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    align-items: center;
    place-items: center;
    position: relative;
    z-index: 2;
}


.Hero .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-weight: 500;
    width: 60px;
    height: auto;
    grid-row: 2/3;
}


.Hero .icon-container img {
    width: 100%;
    height: 100%;

}

.Hero .form-overlay-dark {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    z-index: 12;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.Hero .instant-form {

    z-index: 11;
    direction: rtl;
    top: 50%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
    background-color: var(--dark-purple);
    text-align: center;
    color: white;
    width: 50%;
    height: 50%;
    gap: 1em;
    position: absolute;
    justify-content: center;
    align-items: center;

}


.Hero form {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(4, auto);
    gap: 1em;
    background-color: var(--light-purple);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.9);
    width: 80%;
    height: 60%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);

}

.Hero textarea {
    grid-column: 1/-1;
    grid-row: 2/4;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Hero .send-btn {
    grid-row: 4/5;
    grid-column: -4/-1;
}

.Hero .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0.5em;
    font-size: 1.5em;
}

.Hero .close-btn:hover {
    cursor: pointer;
    transform: scale(1.3);
    color: black;
}

.Hero form input {
    width: 100%;
}

.Hero form input::placeholder,
.Hero form textarea::placeholder {
    padding-right: 0.5em;


}

.Hero .sub-header {
    font-size: 2em;
    font-weight: 700;
    justify-self: start;
    margin-bottom: 2em;
    align-self: self-start;
}

.Hero .fast-contact-btn {
    display: flex;
    justify-content: flex-end;
    gap: 2em;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 200;
}

.Hero .whatsapp-container,
.Hero .phone-container {

    background-color: green;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

}

.Hero .whatsapp-container:hover,
.Hero .phone-container:hover {
    cursor: pointer;


}

.Hero .phone-container {

    background-color: rgb(142, 135, 197);
    display: none;
}

.Hero .phone-icon {
    width: 60%;
    height: 60%;
    color: var(--dark-purple);

}

.Hero .whatsapp-icon {
    width: 70%;
    height: 70%;

}

.Hero .send-btn:hover {
    cursor: pointer;
    background: var(--dark-purple);
    color: white;
}

.Hero .success-message {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    font-size: 60px;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    font-weight: bold;

}



/* responsive */

@media (max-width: 1000px) {

    .Hero .hero-lower-wave {
        bottom: 105px;
    }

    .Hero .rectangle-wave {
        background: var(--dark-purple);
        display: flex;
        padding-left: 12em;
        padding-right: 12em;
        z-index: 2;
        position: relative;
        bottom: 0px
    }

    .Hero .hero-lower-wave {
        bottom: 35px
    }




}


/* /* MOBILE: 375px */

@media (max-width: 375px) {

    .Hero .instant-form {
        width: 90%;
        height: 80%;  
    }


    .Hero .rectangle-wave {
        background: var(--dark-purple);
        display: flex;
        padding-left: 12em;
        padding-right: 12em;
        z-index: 2;
        position: relative;
        bottom: 40px
    }

    .Hero .hero-lower-wave {
        bottom: 35px;

    }



    .Hero .ask-for-details-btn {
        font-size: 0.8em;
    }

    .Hero .three-points {
        display: flex;
        gap: 1em;
        font-size: 0.9em;
        justify-content: center;
    }

    .Hero .hero-content {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        justify-content: start;
        background-image: none;
    }

    .Hero .hero-image {
        position: absolute;
        bottom: 0px;
        width: 70%;
        height: auto;
        z-index: -2;
    }

    .Hero .hero-copy {
        margin-bottom: 0px;
        padding-top: 6em;
    }

    .Hero .hero-copy .first-sen {
        font-size: 1.2em;
    }

    .Hero .hero-copy .third-sen {
        font-size: 0.8em;
    }


    .Hero .ask-for-details-btn {
        align-self: center;
    }


    .Hero .services-icons {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        padding-top: 40px;
        gap: 2em;
        padding-bottom: 40px;

    }

    .Hero .icon-container {
        grid-row: auto;
        width: 40px;

    }

    .Hero form {
        display: flex;
        flex-direction: column;
        height: 70%;
    }

    .Hero form input,
    .Hero form textarea,
    .Hero form .send-btn {
        flex: 1;
    }

    .Hero .sub-header {
        font-size: 1em;
        font-weight: 700;
    }

    .Hero .whatsapp-container,
    .Hero .phone-container {
        width: 50px;
        height: 50px;

    }


    .Hero .phone-container {
        display: flex;

    }


    .Hero .fast-contact-btn {
        display: flex;
        justify-content: flex-end;
        gap: 2em;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 200;
    }


}


/* MOBILE: 380PX - 576PX */
@media (min-width:380px) and (max-width: 576px) {


    .Hero .hero-lower-wave {
        bottom: 107px;
    }

    .Hero .rectangle-wave {
        background: var(--dark-purple);
        display: flex;
        padding-left: 12em;
        padding-right: 12em;
        z-index: 2;
        position: relative;
        bottom: 113px
    }

    .Hero .hero-content {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        justify-content: start;
        background-image: none;
    }

    .Hero .hero-image {
        position: absolute;
        bottom: 0px;
        width: 70%;
        height: auto;
        z-index: -2;
    }

    .Hero .hero-copy {
        margin-bottom: 0px;
        padding-top: 6em;
    }

    .Hero .hero-copy .first-sen {
        font-size: 1.2em;
    }

    .Hero .hero-copy .third-sen {
        font-size: 0.8em;
    }

    .Hero .three-points {
        flex-wrap: wrap;
        gap: 0em;
        justify-content: center;
        column-gap: 0.6em;
        font-size: 0.9em;
        justify-content: center;

    }

    .Hero .ask-for-details-btn {
        align-self: center;
    }


    .Hero .services-icons {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        padding-top: 40px;
        gap: 2em;
        padding-bottom: 40px;

    }

    .Hero .icon-container {
        grid-row: auto;
        width: 40px;

    }


    .Hero .phone-container {
        display: flex;

    }



}

@media (max-width:1100px) {

    .Hero .hero-content {
        padding-left: 1em;
        padding-right: 1em;

    }

    .Hero h2 {
        color: var(--dark-purple);
        font-size: 2.5em;
        font-size: 2em;
    }

    .Hero .hero-image img {
        height: auto;
        width: 90%;
        transform: rotate(358deg);

    }

    .Hero .rectangle-wave {
        background: var(--dark-purple);
        display: flex;
        padding-left: 3em;
        padding-right: 3em;
    }



}